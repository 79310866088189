import axios from "axios";
import {API_URL} from "@/const";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";

export default {
  mixins: [ selectionLocalStorage ],
  data() {
    return {
      storeList: [],
      generatorCollectList: [],
      selectGeneratorCollectList: [], //入力テキストで絞る排出事業者選択肢
      jwnetItemsOpt: [],
      selectGeneratorDisposalList: [], //入力テキストで絞る排出事業者選択肢
      generatorDisposalList: [],
      cbaItems: [],
      manifestStatusCount: [],
      disposalSiteList: [], // 処分場の選択肢
      selectDisposalSiteList: [], // 入力テキストで絞った処分場の選択肢
      generatorCompanyList: [], // 排出事業者の選択肢
      selectGeneratorCompanyList: [], // 入力テキストで絞った排出事業者の選択肢
    };
  },
  methods: {
    // マニフェスト件数取得Api
    getManifestCountApi() {
      axios
          .get(API_URL.MANIFEST.SUMMARY)
          .then((res) => {
            this.manifestStatusCount = res.data.manifestsSummaryList;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    // 運搬事業者選択肢取得API
    getGeneratorCollectApi() {
      this.getGeneratorCollectSelection(API_URL.MANIFEST.GENERATOR_COLLECT_LIST).then((data) => {
        this.generatorCollectList = data;
        this.selectGeneratorCollectList = data;
        if (this.searchParam.collectCompanyId) {
          this.selectedGeneratorCollect = this.generatorCollectList.find(e => e.id === this.searchParam.collectCompanyId)
        }
      }).catch(() => {})
    },

    // 廃棄物種類選択肢取得API    API_URL.DOMAIN.JWNET_ITEM_TYPE
    getJwnetItemsApi() {
      axios
        .get(API_URL.DOMAIN.JWNET_ITEM_TYPE)
        .then((res) => {
          this.jwnetItemsOpt = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 処分事業者選択肢取得API
    getGeneratorDisposalApi() {
      this.getGeneratorDisposalSelection().then((data) => {
        this.generatorDisposalList = data
        this.selectGeneratorDisposalList = data;
        if (this.searchParam.disposalCompanyId) {
          this.selectedGeneratorDisposal = this.generatorDisposalList.find(e => e.id === this.searchParam.disposalCompanyId)
        }        
      }).catch(() => {})
    },

    // CBA品目選択肢取得API   MANIFEST.CBA_ITEMS
    getCbaItemsApi() {
      return axios
        .get(API_URL.MANIFEST.CBA_ITEMS)
        .then((res) => {
          this.cbaItems = res.data.cbaItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 処分事業場取得API
    getDisposalSiteApi() {
      this.getDisposalSiteSelection().then((data) => {
        this.disposalSiteList = data
        this.selectDisposalSiteList = data;
        if (this.searchParam.disposalSiteId) {
          this.selectedDisposalSite = this.disposalSiteList.find(e => e.id === this.searchParam.disposalSiteId)
        }
      }).catch(() => {})
    },

    // 排出の場合、排出事業者選択肢取得API
    getGeneratorCompanyApiForGenerate() {
      this.getGeneratorCompanySelection(API_URL.ENTRUST.GENERATE_SELECT_COMPANY).then((data) => {
        this.generatorCompanyList = data;
        this.selectGeneratorCompanyList = data;
        if (this.searchParam.generateCompanyId) {
          this.selectedGeneratorCompany = this.generatorCompanyList.find(e => e.id === this.searchParam.generateCompanyId)
        }
      }).catch(() => {})
    },

    // 業者による排出事業場選択肢情報取得API
    getSelectoptionGenerateStoreForGenerate() {
      axios
          .get(API_URL.MANIFEST.SELECTOPTION_GENERATESTORE)
          .then((res) => {
            this.storeList = res.data.generateStoreList
            this.generateStoreList = this.storeList.map(f => f.store)
            this.selectGenerateStoreList = this.storeList.map(f => f.store)
            while (this.selectedGenerateStore.length < this.searchParam.generateStoreIdList.length) {
              this.selectedGenerateStore.push(null);
            }
            this.searchParam.generateStoreIdList.forEach( (c, index) => {
              this.selectedGenerateStore[index] = c ? this.generateStoreList.find(e => e.id === c) : null
            })
            this.isLoadingSearchGenerateStore = false
          }).catch((err) => {
        console.log(err);
      })
    }
  },
};
