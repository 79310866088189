<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="完了マニフェスト" />
    <AppSideMenu />
    <main class="l-main">
      <ToastMessage :toastMsg.sync="toastMsg" />
      <!-- 検索フォーム ============================================================ -->
      <SearchForm
        @search="getManifestSearchApi"
      />

      <!-- 検索結果 ============================================================ -->
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />

        <div v-if="manifestList.length" class="l-flex cols l-item" :class="{ disabled: isCallApiProcess }">
          <div
            class="c-btn secondary small upload"
            style="margin-left: auto;!important;width: 16rem;"
            @click="onClickCsvUploadModalDisplayButton"
          >
            CSVアップロード
          </div>
          <div
              class="c-btn secondary small download"
              style="width: 16rem;"
              @click="$refs.csvdownloadmodal.updateCSVModal(true)"
              @click_backup="clickCSVSearchHandler"
          >
            CSVダウンロード
          </div>
        </div>

        <div class="c-infoList__wrap">
          <div class="c-infoList scroll l-box">
            <div class="c-infoList__row head">
              <div class="c-infoList__cell md-s">マニフェスト番号</div>
              <div class="c-infoList__cell sm">廃棄物区分</div>
              <div class="c-infoList__cell sm">廃棄物名称</div>
              <div class="c-infoList__cell sm">詳細名称</div>
              <div class="c-infoList__cell sm">数量</div>
              <div class="c-infoList__cell sm">確定数量</div>
              <div class="c-infoList__cell full-date">
                <a @click="clickSortChangeHandler('collection_date')">
                    <span class="c-infoList__cell__sort" :class="{
                        asc: sortParams.type.toLowerCase() === 'asc' && sortParams.column === 'collection_date',
                        desc: sortParams.type.toLowerCase() === 'desc' && sortParams.column === 'collection_date',
                        'sort-disabled': sortParams.column !== 'collection_date',
                      }"
                    >引き渡し日&nbsp;/&nbsp;交付日</span>
                </a>
              </div>
              <div class="c-infoList__cell md-s">
                <a @click="clickSortChangeHandler('final_disposal_report_date')">
                    <span class="c-infoList__cell__sort" :class="{
                        asc: sortParams.type.toLowerCase() === 'asc' && sortParams.column === 'final_disposal_report_date',
                        desc: sortParams.type.toLowerCase() === 'desc' && sortParams.column === 'final_disposal_report_date',
                        'sort-disabled': sortParams.column !== 'final_disposal_report_date',
                      }"
                    >最終処分終了日&nbsp;/&nbsp;報告日</span>
                </a>
              </div>
              <div class="c-infoList__cell sm">ステータス</div>
            </div>

            <!-- 検索結果あり -->
            <template v-if="manifestList.length">
              <div
                  v-for="(manifest, index) of manifestList"
                  :key="'manifest-' + index"
                  class="c-infoList__row list"
                  @click="toDetail(manifest.manifestId)"
              >
                <div class="c-infoList__cell md-s c-tooltip">
                  <router-link @click.native="setManifestCompletePage()" :to="`/manifest/${manifest.manifestId}`">
                    {{ manifest.manifestNo | blankText }}
                    {{ manifest.manifestRegistType == manifestRegistType.COLLECTOR_REGIST ?  "（運代）" : "" }}
                    {{ manifest.manifestRegistType == manifestRegistType.DISPOSER_REGIST ? "（処代）" : "" }}
                  </router-link>
                  <div class="c-status info-red"
                       v-if="manifest.isFinalDisposalSiteNG"
                  >
                    <div class="c-infoList__popup">
                      <p>最終処分場チェックでエラーです</p>
                    </div>
                  </div>
                </div>
                <div class="c-infoList__cell sm text-dotted">
                  {{ manifest.wasteType ? manifest.wasteType.name : "" }}
                </div>
                <div class="c-infoList__cell sm text-dotted">
                  {{ manifest.wasteName ? manifest.wasteName.name : "" }}
                </div>
                <div class="c-infoList__cell sm text-dotted">
                  {{ manifest.detailWasteName ? manifest.detailWasteName : "" }}
                </div>
                <div class="c-infoList__cell sm text-dotted">
                  {{ manifest.wasteQuantity }} {{ manifest.wasteUnitType ? manifest.wasteUnitType.name : "" }}
                </div>
                <div class="c-infoList__cell sm text-dotted">
                  {{ manifest.fixedWasteQuantity ? manifest.fixedWasteQuantity + (manifest.fixedWasteQuantityUnitType ? manifest.fixedWasteQuantityUnitType.name : "") : "" | blankText }}
                </div>
                <div class="c-infoList__cell full-date">
                  <span class="u-txt-separator">{{ manifest.collectionDate | dateJpYMDddFormat }}</span>
                  <span class="u-txt-sm u-txt-light-grey">{{ manifest.issueDate | dateJpMDddFormat }}</span>
                </div>

                <!-- 最終処分終了報告日/登録日 -->
                <!-- 最終処分終了日/報告日 -->
                <template v-if="manifest.finalDisposalReportDate">
                  <div class="c-infoList__cell md-s c-tooltip">
                    <template v-if="manifest.finalDisposalReportDate === manifest.finalDisposalRegistDate">
                      <span>{{ manifest.finalDisposalReportDate | dateJpMDddFormat }}</span>
                    </template>
                    <template v-else>
                      <span class="u-txt-separator">{{ manifest.finalDisposalReportDate | dateJpMDddFormat }}</span>
                      <span class="u-txt-sm u-txt-light-grey">{{ manifest.finalDisposalRegistDate | dateJpMDddFormat }}</span>
                    </template>
                    <div class="c-status info-red"
                      v-if="manifest.isExpiredCollectionDate || manifest.isExpiredDisposalDate || manifest.isExpiredFinalDisposalDate"
                    >
                      <div class="c-infoList__popup">
                        <p v-if="manifest.isExpiredCollectionDate">運搬終了報告期限切れ</p>
                        <p v-if="manifest.isExpiredDisposalDate">処分終了報告期限切れ</p>
                        <p v-if="manifest.isExpiredFinalDisposalDate">最終処分終了報告期限切れ</p>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="c-infoList__cell md-s">
                    <span>未報告</span>
                  </div>
                </template>

                <div class="c-infoList__cell sm text-dotted">
                  {{ manifest.manifestStatusType != null ? manifest.manifestStatusType.name : "" }}
                </div>
              </div>
            </template>

            <!-- 検索結果なし -->
            <template v-else>
              <div class="c-infoList__row list">
                <div class="c-infoList__cell">
                  検索条件に一致するマニフェストはありません。
                </div>
              </div>
            </template>
          </div>
        </div>

        <Pagination v-if="manifestList.length"
          :params="paginationParams"
          :page.sync="paginationParams.page"
          @callback="getManifestSearchApi(searchedParam, true)"
        />
      </div>

      <CSVDownloadModal
        ref="csvdownloadmodal"
        :paginationParams="paginationParams"
        @downloadCSVHandler="downloadCSVHandler"
      />
    </main>

    <AppFooter />
    <ManifestCsvUploadModal v-if="isDisplayCsvUploadModal"
      v-bind:onClickCloseButton="hideCsvUploadModal"
      v-bind:onClickUploadButton="onClickUploadCsv"
      v-bind:onClickUploadRegisterCsvButton="onClickUploadRegisterCsv"
    />

  </div>
</template>

<script>
import SearchForm from "./components/SearchForm.vue";
import Pagination from "@/components/parts/Pagination";
import search from "./mixins/search";
import moment from "moment";
import {API_URL, DOMAIN} from "@/const";
import axios from "axios";
import { saveAs } from "file-saver";
import CSVDownloadModal from "../components/CSVDownloadModal";
import ToastMessage from "@/components/parts/ToastMessage";
import manifestCallApi from "../mixins/callApi";
import ManifestCsvUploadModal from "../components/ManifestCsvUploadModal";

export default {
  name: "manifest-complete",
  components: {
    SearchForm,
    Pagination,
    CSVDownloadModal,
    ToastMessage,
    ManifestCsvUploadModal,
  },
  mixins: [search, manifestCallApi],
  data() {
    return {
      manifestList: [],
      // 検索内容別で持つ。ページ移動でもAPI叩くため
      searchedParam: {},
      // ページネーション用
      paginationParams: {
        page: 1,
        pageSize: 20,
        total: null,
      },
      sortParams: {
        column: "collection_date",
        type: "desc",
      },
      errMsgs: [],
      toastMsg: "",
      isDisplayCsvUploadModal: false,
      isCallApiProcess: false,
      manifestRegistType: DOMAIN.MANIFEST_REGIST_TYPE,
    };
  },
  methods: {
    // cs202201：CSVフォーマット関連
    /**
     * CSVフォーマット選択モーダル内ダウンロードボタン押下時
     */
    downloadCSVHandler(selectID) {
      // 2022.03.09 全項目とフォーマット指定のAPIを一本化
      this.clickCSVSearchByFormatHandler(selectID);
    },

    // CSVダウンロード用の検索条件パラメーター
    getCSVDownloadSearchParam() {
      let generateStoreIdList = [];
      if (this.searchedParam.generateStoreIdList) {
        this.searchedParam.generateStoreIdList.forEach (
            generateStoreId => {
              if (generateStoreId) {
                generateStoreIdList.push(generateStoreId);
              }
            }
        )
      }

      return {
        searchConditionId: String(DOMAIN.MANIFEST_SEARCH_CONDITION.COMPLETE),
        wasteName: this.searchedParam.wasteName === "" ? null : this.searchedParam.wasteName,
        collectCompanyId: this.searchedParam.collectCompanyId === "" ? null : this.searchedParam.collectCompanyId,
        generateCompanyId: this.searchedParam.generateCompanyId === "" ? null : this.searchedParam.generateCompanyId,
        generateStoreId: generateStoreIdList,
        collectionDateFrom: this.searchedParam.collectionDateFrom === "" ? null : this.searchedParam.collectionDateFrom,
        collectionDateTo: this.searchedParam.collectionDateTo === "" ? null : this.searchedParam.collectionDateTo,
        disposalCompanyId: this.searchedParam.disposalCompanyId === "" ? null : this.searchedParam.disposalCompanyId,
        disposalSiteId: this.searchedParam.disposalSiteId === "" ? null : this.searchedParam.disposalSiteId,
        manifestNo: this.searchedParam.manifestNo === "" ? null : this.searchedParam.manifestNo,
        jwnetItemTypeId: this.searchedParam.jwnetItemTypeId === "" ? null : this.searchedParam.jwnetItemTypeId,
        cbaItemId: this.searchedParam.cbaItemId === "" ? null : this.searchedParam.cbaItemId,
        wasteTypeList: this.searchedParam.wasteType,
        jwnetItemTypeFieldList: this.searchedParam.jwnetItemTypeField,
        routeId: this.searchedParam.routeId === "" ? null : this.searchedParam.routeId,
        routeName: this.searchedParam.routeName === "" ? null : this.searchedParam.routeName,
        termDateTypeList: this.searchedParam.termDateType,
        reportDateFrom: this.searchedParam.reportDateFrom === "" ? null : this.searchedParam.reportDateFrom,
        reportDateTo: this.searchedParam.reportDateTo === "" ? null : this.searchedParam.reportDateTo,
        registDateFrom: this.searchedParam.registDateFrom === "" ? null : this.searchedParam.registDateFrom,
        registDateTo: this.searchedParam.registDateTo === "" ? null : this.searchedParam.registDateTo,
        contactNo: this.searchedParam.contactNo === "" ? null : this.searchedParam.contactNo,
        remarks: this.searchedParam.remarks === "" ? null : this.searchedParam.remarks,
        manifestLabel: this.searchedParam.manifestLabel === "" ? null : this.searchedParam.manifestLabel,
        isMine: this.searchedParam.isMine,
        isActive: this.searchedParam.isActive,
        manifestSearchType: this.searchedParam.status,
        sort: this.sortParams.column + ":" + this.sortParams.type
      };
    },

    // CSVダウンロード押下
    clickCSVSearchHandler() {
      this.errMsgs = [];
      axios
        .post(API_URL.MANIFEST.CSV_DOWNLOAD
            , this.getCSVDownloadSearchParam()
            , { responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          saveAs(blob, `マニフェスト検索結果_${moment().format("YYYYMMDDHHmmss")}.csv`);
          this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
          this.$refs.csvdownloadmodal.updateCSVModal(false);
        });
    },


    // フォーマット指定したCSVダウンロード押下
    clickCSVSearchByFormatHandler(csvformatId) {
      this.errMsgs = [];
      axios
          .post(API_URL.MANIFEST.CSV_DOWNLOAD_BY_FORMAT
              , {
                manifestSearchFileDownloadInfo: this.getCSVDownloadSearchParam(),
                csvformatId: csvformatId
              }
              , { responseType: "blob" }
          )
          .then((res) => {
            const blob = new Blob([res.data], {
              type: res.data.type
            });
            saveAs(blob, `マニフェスト検索結果_${moment().format("YYYYMMDDHHmmss")}.csv`);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
          })
          .catch((err) => {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
            this.$refs.csvdownloadmodal.updateCSVModal(false);
          });
    },

    // ソート変更（引き渡し日、最終処分終了報告日）
    clickSortChangeHandler(column) {
      if (this.sortParams.column !== column) {
        this.sortParams.type = "desc";
      } else {
        this.sortParams.type = this.sortParams.type.toLowerCase() === "asc" ? "desc" : "asc";
      }
      this.sortParams.column = column;

      // リセットする
      this.$store.commit("searchParamManifestsComplete/setManifestCompletePage", 1);
      this.getManifestSearchApi(this.searchedParam, false);
    },
    toDetail(manifestId) {
      this.setManifestCompletePage()
      const transitionSource = location.pathname;
      this.$router.push(`/manifest/${manifestId}?transitionSource=${transitionSource}`);
    },
    hideCsvUploadModal() {
      this.isDisplayCsvUploadModal = false;
    },
    onClickCsvUploadModalDisplayButton() {
      this.isDisplayCsvUploadModal = true;
    },

    onClickUploadCsv(csvFile) {
      return this.postManifestCsvupload(csvFile)
        .then(this.onUpload);
    },
    onClickUploadRegisterCsv(csvFile) {
      return this.postManifestCsvregister(csvFile)
        .then(this.onUpload);
    },
    onUpload() {
      this.hideCsvUploadModal();
      this.toastMsg = "正常にアップロードしました。";
      // 再検索を行う
      this.$store.commit("searchParamManifestsComplete/setManifestCompletePage", 1);
      this.getManifestSearchApi(this.$store.getters["searchParamManifestsComplete/get"], false);
    },
    setManifestCompletePage() {
      this.$store.commit("searchParamManifestsComplete/setManifestCompletePage", this.paginationParams.page);
    },
    addRequestToLocation(requestObj) {
      const param = new URLSearchParams(requestObj)
      history.replaceState(
      {},
      null,
      this.$route.path + '?' + param
    )
    }
  },
  beforeRouteEnter(to, from, next) {
    next( (vm) => {
      if(from.path.includes("/manifest")) {
        let page = vm.$store.getters["searchParamManifestsComplete/getManifestCompletePage"]
        vm.paginationParams.page = page
      } else {
        // リセットする
        vm.$store.commit("searchParamManifestsComplete/setManifestCompletePage", 1);
      }
    })
  },

  beforeCreate() {
    // クエリパラメータのチェック
    const status = this.$route.query.status ? this.$route.query.status.split(",").map(s => Number(s)) : [];
    if (this.$route.query.registDateFrom || this.$route.query.registDateTo || this.$route.query.termDateTypeList) {
      this.$store.commit("searchParamManifestsComplete/set", {
        wasteName: "",
        manifestNo: "",
        collectionDateFrom: "",
        collectionDateTo: "",
        cbaItemId: "",
        jwnetItemTypeId: "",
        routeId: "",
        routeName: "",
        collectCompanyId: "",
        generateCompanyId: "",
        generateStoreIdList: [null],
        disposalCompanyId: "",
        disposalSiteId: "",
        wasteType: [],
        termDateType: this.$route.query.termDateTypeList ? this.$route.query.termDateTypeList.split(",").map(s => Number(s)) : [],
        reportDateFrom: "",
        reportDateTo: "",
        registDateFrom: this.$route.query.registDateFrom ? this.$route.query.registDateFrom : "",
        registDateTo: this.$route.query.registDateTo ? this.$route.query.registDateTo : "",
        contactNo: "",
        remarks: "",
        manifestLabel: "",
        isMine: true,
        isActive: true,
        status: status,
      });
    } else if (this.$route.query.status) {
      this.$store.commit("searchParamManifestsComplete/set", {
        wasteName: "",
        manifestNo: "",
        collectionDateFrom: "",
        collectionDateTo: "",
        cbaItemId: "",
        jwnetItemTypeId: "",
        routeId: "",
        routeName: "",
        collectCompanyId: "",
        generateCompanyId: "",
        generateStoreIdList: [null],
        disposalCompanyId: "",
        disposalSiteId: "",
        wasteType: [],
        termDateType: [],
        reportDateFrom: "",
        reportDateTo: "",
        registDateFrom: "",
        registDateTo: "",
        contactNo: "",
        remarks: "",
        manifestLabel: "",
        isMine: true,
        isActive: true,
        status: status,
      });
    }
  },

  mounted() {
    this.getManifestSearchApi(this.$store.getters["searchParamManifestsComplete/get"], false);
  },
};
</script>
